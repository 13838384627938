import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com'
import { init } from '@emailjs/browser';
import Layout from '../components/layout';
import SEO from "../components/seo";
import CustomPopup from '../components/Popup/custom-popup';
import Form from 'react-bootstrap/Form';
import { submitActiveCampaignForm } from '../utils/campaign';
import "../scss/styles.scss";
import "../styles/style.css";
import HomeNew from './home-new';
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const IndexPage = () => {
  const seo = { metaDesc: 'Patents, Trademarks, Copyrights, Trade Secrets, Provisional Patent, Utility Patent, USPTO' }

  return (
    <Layout>
      <SEO title="Home"  canonical='/' seo={seo} />
      {/* <HomeQuiz /> */}
      <HomeNew />
    </Layout>
  )
};

export default IndexPage;